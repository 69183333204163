<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Alterar Senha</h3>
    </v-col>
    <v-col cols="12" class="mt-1">
      <v-card outlined>
        <v-card-title class=""
          ><v-icon class="mr-2">lock</v-icon>
          <h5 class="title">Alterar senha</h5></v-card-title
        >
        <v-card-text class="">
          <v-row wrap justify="start" align="start" dense>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                dense
                :clear="clear"
                outlined
                block
                label="senha antiga"
                :type="'password'"
                placeholder="digite a senha antiga..."
                v-model="password.currentPassword"
                :rules="passRules"
                counter="25"
                @keypress.enter="changePassword()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                dense
                outlined
                :clear="clear"
                block
                label="nova senha"
                :type="'password'"
                placeholder="nova senha..."
                v-model="password.newPassword"
                :rules="passRules"
                counter="25"
                @keypress.enter="changePassword()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                dense
                outlined
                :clear="clear"
                block
                label="confirmar nova senha"
                :type="'password'"
                placeholder="confirmar nova senha"
                v-model="password.confirmPassword"
                :rules="passRules"
                counter="25"
                @keypress.enter="changePassword()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="12" md="3">
              <v-btn outlined color="blue" @click="changePassword()"
                >Alterar Senha<v-icon>check</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                indeterminate
                color="red"
                v-show="progress"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        <v-row justify="center">
          {{ snackBarMessage }}
        </v-row>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UpdatePasswords",
  props: [],
  data() {
    return {
      progress: false,
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "Senha alterada com sucesso!",
      clear: false,
      password: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      passRules: [
        (p) =>
          !p || p.length < 25 || "A senha não pode ter mais que 25 caracteres!",
        (p) => !!p || "A senha não pode ser vazia!",
      ],
    };
  },

  methods: {
    ...mapActions("editCandidate", ["ActionGetCandidate", "ActionPutPassword"]),

    async changePassword() {
      if (
        this.password.currentPassword !== "" &&
        this.password.newPassword !== "" &&
        this.password.confirmPassword !== ""
      ) {
        try {
          this.progress = true;
          let res = await this.ActionPutPassword(this.password);
          this.snackbarMessage = res.bodyText;
          this.clear = true;
        } catch (error) {
          this.snackBarMessage = "Erro ao alterar a senha! " + error.bodyText;
        } finally {
          this.snackbar = true;
          this.progress = false;
        }
      }
    },
  },
};
</script>

<style>
</style>