var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Alterar Senha")])
      ]),
      _c(
        "v-col",
        { staticClass: "mt-1", attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-title",
                {},
                [
                  _c("v-icon", { staticClass: "mr-2" }, [_vm._v("lock")]),
                  _c("h5", { staticClass: "title" }, [_vm._v("Alterar senha")])
                ],
                1
              ),
              _c(
                "v-card-text",
                {},
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        wrap: "",
                        justify: "start",
                        align: "start",
                        dense: ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              clear: _vm.clear,
                              outlined: "",
                              block: "",
                              label: "senha antiga",
                              type: "password",
                              placeholder: "digite a senha antiga...",
                              rules: _vm.passRules,
                              counter: "25"
                            },
                            on: {
                              keypress: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.changePassword()
                              }
                            },
                            model: {
                              value: _vm.password.currentPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.password, "currentPassword", $$v)
                              },
                              expression: "password.currentPassword"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              clear: _vm.clear,
                              block: "",
                              label: "nova senha",
                              type: "password",
                              placeholder: "nova senha...",
                              rules: _vm.passRules,
                              counter: "25"
                            },
                            on: {
                              keypress: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.changePassword()
                              }
                            },
                            model: {
                              value: _vm.password.newPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.password, "newPassword", $$v)
                              },
                              expression: "password.newPassword"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              clear: _vm.clear,
                              block: "",
                              label: "confirmar nova senha",
                              type: "password",
                              placeholder: "confirmar nova senha",
                              rules: _vm.passRules,
                              counter: "25"
                            },
                            on: {
                              keypress: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.changePassword()
                              }
                            },
                            model: {
                              value: _vm.password.confirmPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.password, "confirmPassword", $$v)
                              },
                              expression: "password.confirmPassword"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "blue" },
                              on: {
                                click: function($event) {
                                  return _vm.changePassword()
                                }
                              }
                            },
                            [
                              _vm._v("Alterar Senha"),
                              _c("v-icon", [_vm._v("check")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.progress,
                                expression: "progress"
                              }
                            ],
                            attrs: { indeterminate: "", color: "red" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.timeout },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _c("v-row", { attrs: { justify: "center" } }, [
                _vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }